import { HubConnectionBuilder } from '@microsoft/signalr'

class MessageHub{
    constructor(){
        this.client = new HubConnectionBuilder()
            .withUrl(`${process.env.VUE_APP_API_ENDPOINT}messages`)
            .build();
    }

    start(){
        this.client.start();
    }

    stop(){
        this.client.stop();
    }

    state(){
        return this.client.state;
    }
}

export default new MessageHub();