// toastPlugin.js
import Vue from 'vue';
import Toast from '@/components/molecules/ToastBar.vue';

const ToastProgrammatic = {
  open(params) {
    const ToastClass = Vue.extend(Toast);
    const instance = new ToastClass({
      propsData: params
    });
    instance.$mount();
    document.body.appendChild(instance.$el);
    return instance;
  }
};

let $toast = null;

export default {
  install(Vue, options = {}) {
    Vue.prototype.$toast = ToastProgrammatic;
    $toast = Vue.prototype.$toast;
  }
};

export { $toast };
