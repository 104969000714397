<template>
  <div id="app">
    <router-view/>
    <notification-panel/>
  </div>
</template>

<script>
  import NotificationPanel from '@/components/molecules/NotificationPanel.vue'

  import { mapState } from 'vuex'
  import baseMixin from '@/mixins/mixin'

  export default{
    name: 'MainView',
    components: { NotificationPanel },
    mixins: [baseMixin],
    data(){
        return{
        }
    },
    computed: {
      ...mapState(['user']),
      currentPageName() {
        return this.$route.name;
      }
    },
    mounted(){
      this.tasksNotification()
      this.mortgageNotification()
      this.chatMessagedNotification()

      if(this.$messageHub.state() === 'Disconnected')
        this.$messageHub.start();
    },
    methods: {
      mortgageNotification(){
        var that = this
        this.$messageHub.client.on("AddUpdateMortgage", function(d){
            var data = {
              id: d.id,
              message: `A mortgage has been created/updated for ${d.clientName}`,
              created: new Date()
            }
            that.$store.commit('SET_NOTIFICATIONS', data)

            that.$toast.open({
              message: `A mortgage has been created/updated for ${d.clientName}`,
              type: 'is-info'
            });
        })
      },
      tasksNotification(){
        var that = this
        this.$messageHub.client.on("TaskAddUpdate", function(task){
            if(that.user.id === task.assignedTo){
                var data = {
                    id: task.id,
                    assignedTo: task.assignedTo,
                    message: `A task has been assgined to you by ${task.assignedByName}`,
                    created: task.createdAt
                }
                that.$store.commit('SET_NOTIFICATIONS', data)

                that.$toast.open({ 
                    message: `A task has been assgined to you by ${task.assignedByName}`,
                    type: 'is-info'
                })

            }
        })
      },
      chatMessagedNotification(){
        var that = this
        this.$messageHub.client.on("SendChatMessage", function(message){
          that.$store.commit('SET_RECENTSENTCHATLOG', message)
        })
      }
    }
  }
</script>
<style>
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-icons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-layouts/styles/material.css";
@import "../node_modules/@syncfusion/ej2-grids/styles/material.css";
@import "../node_modules/@syncfusion/ej2-vue-filemanager/styles/material.css";
</style>
