<template>
    <div class="notification-toast" :style="toastBackgroundColor">
            <div class="notification__body">
                <img
                    :src="toastIcon" 
                    alt="Success"
                    class="notification__icon">
                {{ message }}
            </div>
            <div class="notification__progress" :style="toastProgressColor"></div>
        </div>
</template>

<script>
export default {
  name: 'ToastBar',
  props: {
    message: { type: String, default: '' },
    type: { type: String, default: '' }
  },
  computed:{
    toastIcon(){
        if(this.type === 'is-success')
            return require(`@/assets/check-circle.svg`);
        
        if(this.type === 'is-danger')
            return require(`@/assets/icon-danger.png`);

        if(this.type === 'is-info')
            return require(`@/assets/icon-info.svg`);
    },
    toastBackgroundColor(){
        if(this.type === 'is-success')
            return 'background-color: #313e2c; color: #aaec8a'
        
        if(this.type === 'is-danger')
            return 'background-color: #3e2c2c; color: #ec8a8a'

        if(this.type === 'is-info')
            return 'background-color: #1e53bc; color: #b3bfeb'
    },
    toastProgressColor(){
        if(this.type === 'is-success')
            return 'background: linear-gradient(to right, #313e2c,  #aaec8a);'

        if(this.type === 'is-danger')
            return 'background: linear-gradient(to right, #3e2c2c,  #ec8a8a);'

        if(this.type === 'is-info')
            return 'background: linear-gradient(to right, #1e53bc,  #b3bfeb);'
    }
  }
}
</script>

<style lang="scss">

.notification-toast {
    position: fixed;
    width: max-content;
    left: 0; 
    right: 0; 
    bottom: 24px;
    margin-left: auto; 
    margin-right: auto; 
    border-radius: 6px;
    // background-color: var(
    //     --notification-background);
    // color: var(--notification-primary);
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    transform: translateY(30px);
    opacity: 0;
    visibility: hidden;
    animation: fade-in 5s linear;
}

.notification__icon {
    height: 26px;
    width: 26px;
    margin-right: 4px;
}

.notification__body {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 8px;
}

.notification__progress {
    position: absolute;
    left: 4px;
    bottom: 4px;
    width: calc(100% - 8px);
    height: 3px;
    transform: scaleX(0);
    transform-origin: left;
    // background: linear-gradient(
    //     to right, 
    //     var(--notification-background),  
    //     var(--notification-primary)
    // );
    border-radius: inherit;
    animation: progress 2.5s 0.3s linear;
}

@keyframes fade-in {
    5% {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
    95% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes progress {
    to {
        transform: scaleX(1);
    }
}
</style>