<template>
    <aside class="aside is-placed-right overlay" :class="isNotificationExpanded ? 'show' : 'hide'">
        <div class="aside-container overlay-content">
            <div v-if="notifications.length > 0">
                <b-tag icon="close" class="is-clickable mb-3" rounded @click="clearAllNotifications">Clear All</b-tag>
                <article v-for="n in notifications" class="media notification">
                    <div class="media-content">
                        <div class="content">
                            <p>{{ n.message }}</p>
                        </div><nav class="level is-mobile">
                            <div class="level-left">
                                <div class="level-item">
                                    <span class="tag is-small is-danger">
                                        <span class="icon is-small">
                                            <i class="fas fa-info"></i>
                                        </span>
                                    </span>
                                </div>
                                <div class="level-item">
                                    <small>{{ timeElapsed(n.createdAt) }}</small>
                                </div>
                            </div>
                        </nav>
                    </div>
                </article>
                <hr/>
            </div>
            <p v-if="notifications.length == 0">No Notifications</p>
        </div>
    </aside>
</template>

<script>
import { mapState } from 'vuex'
import baseMixin from '@/mixins/mixin'
export default {
  name: 'NavBar',
  components: { },
  mixins: [baseMixin],
  data () {
    return {
    }
  },
  computed: {
    ...mapState(['user', 'allClients', 'isNotificationExpanded', 'notifications'])
  },
  methods:{
    clearAllNotifications(){
        this.$store.commit('SET_NOTIFICATIONS', null)
    }
  },
  watch: {
    notifications(newValue, oldValue) {
        if(newValue.length > oldValue.length){
            this.$toast.open({ 
                message: 'You have a new notification',
                type: 'is-info'
            })
        }
    }
  },
}
</script>

<style>
.overlay {
    height: 100vh;
    min-width: 300px;
    max-width: 300px;
    position: fixed; 
    z-index: 1; 
    top: 56px;
    right: 0;
    overflow-x: hidden; 
    transition: 0.2s ease-in;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.overlay.show{
    transform: translateX(0);
}
.overlay.hide{
    transform: translateX(100%);
}

.overlay-content{
  padding: 15px;
  position: relative;
  float: right;
  height: 100vh;
  min-width: 300px;
  max-width: 300px;
  background-color: white;
}

.notification{
    border-radius: 10px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
}
</style>