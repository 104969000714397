<template>
    <div class="login-container gravesend-font">
      <div class="forms-container">
        <div class="signin-signup">
          <form @submit.prevent="login" class="sign-in-form">
            <div style="display: flex;flex-direction: column;align-items: center;">
              <img src="../assets/lockhart-murphy-black.png" style="max-width: 50%;" class="image" alt="" />
            </div>
            <h2 class="title">Sign in</h2>
            <div class="input-field">
              <label for="email">Email</label>
              <!-- <fa-icon icon="user"></fa-icon> -->
              <input v-model="form.Email" type="email" id="email" name="email" placeholder=" " />
            </div>
            <div class="input-field">
              <label for="password">Password</label>
              <!-- <fa-icon icon="lock"></fa-icon> -->
              <input v-model="form.Password" type="password" id="password" name="password" placeholder=" " />
            </div>
            <button type="submit" class="button btn solid" :class="{'is-loading': isLoading }" style="border-radius: 0;">Login</button>
            <p><a class="has-text-black" @click="forgotPassword">Forgot Password?</a></p>
            <p class="social-text">Check us out on socials</p>
            <div class="social-media">
              <a href="https://www.facebook.com/mortgix" target="_blank" class="social-icon">
                <fa-icon :icon="['fab', 'facebook-f']"></fa-icon>
              </a>
              <a href="#" class="social-icon">
                <fa-icon :icon="['fab', 'twitter']"></fa-icon>
              </a>

              <a href="#" class="social-icon">
                <fa-icon :icon="['fab', 'linkedin-in']"></fa-icon>
              </a>
            </div>
          </form>
          <form @submit.prevent="signUp" class="sign-up-form">
            <h2 class="title">Sign up</h2>
            <div class="input-field">
              <input v-model="signUpData.firstName" type="text" placeholder="Firstname" />
            </div>
            <div class="input-field">
              <input v-model="signUpData.lastName" type="text" placeholder="Lastname" />
            </div>
            <div class="input-field">
              <input v-model="signUpData.email" type="email" placeholder="Email" />
            </div>
            <div class="input-field">
              <input v-model="signUpData.password" type="password" placeholder="Password" />
            </div>
            <button class="button btn" :class="{'is-loading': isLoading }" @click="signUp">Sign up</button>
            <p class="social-text">Check us out on socials</p>
            <div class="social-media">
              <a href="www.facebook.com/mortgix" target="_blank" class="social-icon">
                <fa-icon :icon="['fab', 'facebook-f']"></fa-icon>
              </a>
              <a href="#" class="social-icon">
                <fa-icon :icon="['fab', 'twitter']"></fa-icon>
              </a>

              <a href="#" class="social-icon">
                <fa-icon :icon="['fab', 'linkedin-in']"></fa-icon>
              </a>
            </div>
          </form>
        </div>
      </div>

      <div class="panels-container gravesend-font">
        <div class="panel left-panel">
          <div class="content">
            <!-- <h3 class="has-text-white">Welcome to Mortgix</h3> -->
            <div style="display: flex;flex-direction: column;align-items: center;">
              <img src="../assets/mortgix-logo-2.png" style="max-width: 50%;" class="image" alt="" />
            </div>

            <p class="mt-5 is-size-5">CRM</p>
            <!-- <p class="mt-5">
              Revolutionizing Mortgage Brokerage Management!
              Unlock Efficiency. Power Your Mortgage Success!
            </p> -->
            <button class="btn transparent" id="sign-up-btn">Sign up</button>
          </div>
        </div>
        <div class="panel right-panel">
          <div class="content">
            <div style="display: flex;flex-direction: column;align-items: center;">
              <img src="../assets/mortgix-logo-2.png" style="max-width: 50%;" class="image" alt="" />
            </div>
            <p class="mt-5">
              Fill in the details to the left and we will get you activated.
            </p>
            <button class="btn transparent" id="sign-in-btn">Sign in</button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import Axios from 'axios';
import { mapState } from 'vuex'
import baseMixin from '@/mixins/mixin'
export default{
    name: 'LoginView',
    components: {},
    mixins: [baseMixin],
    data(){
        return{
            isLoading: false,
            form: {
                Email: '',
                Password: ''
            },
            signUpData: {}
        }
    },
    mounted(){
        const sign_in_btn = document.querySelector("#sign-in-btn");
        const sign_up_btn = document.querySelector("#sign-up-btn");
        const container = document.querySelector(".login-container");

        sign_up_btn.addEventListener("click", () => {
          container.classList.add("sign-up-mode");
        });

        sign_in_btn.addEventListener("click", () => {
          container.classList.remove("sign-up-mode");
        });
    },
    created(){
      if(this.isLoggedIn && new Date(this.user.tokenExpiration) > new Date()){
        //this.$router.push({ name: 'Home' }).catch((error) => {});
      }
    },
    methods: {
        login() {
            this.isLoading = true
            var that = this

            this.$store.dispatch('authenicate', { url: "Auth/login", form: this.form }).then(async () => {
              this.isLoading = false;
              that.$router.push({ name: 'Home' })
            }).catch(() => {
                this.isLoading = false;
            });
        },
        forgotPassword(){
          this.$buefy.dialog.prompt({
                message: `Enter your email address to reset your password`,
                inputAttrs: {
                    placeholder: 'Email'
                },
                trapFocus: true,
                onConfirm: (value) => {
                  Axios.post(`Auth/forgot-password/${value}`).then(() => {
                    this.$toast.open({ 
                      message: `Reset password email sent`,
                      type: 'is-success'
                    })
                  })
                }
            })
        },

        signUp(){
          var that = this
          this.isLoading = true

          this.signUpData.encryptedPassword = ''
          this.signUpData.UserRolesId = 2
          this.signUpData.dob = new Date()
          this.signUpData.isActivated = false
          Axios.post(`User/add-user`, this.signUpData).then(() => {
              that.isLoading = false
              that.$toast.open({ 
                  message: 'Your account has been created. A manager needs to authorise your account',
                  type: 'is-success'
              })
              that.isLoading = false
          }).catch((error) => {
            that.isLoading = false
              that.$toast.open({ 
                  message: error.message,
                  type: 'is-danger'
              })
          })
        }
    },
    computed: {
      ...mapState(['user', 'isAsideExpanded']),
    },
    watch:{
    }
}
</script>

<style>
</style>