import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import Axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
        storage: window.sessionStorage,
        paths: ['user', 'allClients', 'propertyTypes', 'numOfDueTasks', 'isAsideExpanded', 
        'isNotificationExpanded', 'notifications', 'notes', 'tasks', 'isChatMessageOpen', 'selectedChatLog']
    })
  ],
  state: {
        /* User */
        user: null,
        userName: null,
        userFirstName: null,
        userLastName: null,
        userEmail: null,
        userAvatar: null,
        userRole: null,

        allClients: null,
        numOfDueTasks: null,
        propertyTypes: [],

        notifications: [],
        notes: { results: [] },
        tasks: { results: [] },
        selectedChatLog: null,

        /**General*/
        isAsideExpanded: true,
        isNotificationExpanded: false,
        isChatMessageOpen: false,
        endpointPromise: {}, //store the promise here, and check it to provent multiple loads

  },
  getters: {
  },
  mutations: {
    /* A fit-them-all commit */
    basic(state, payload) {
        state[payload.key] = payload.value
    },
    asideMobileStateToggle(state, payload = null) {
        let isShow

        if (payload !== null) {
            isShow = payload
        } else {
            isShow = !state.isAsideExpanded
        }

        state.isAsideExpanded = isShow
    },
    /* User */
    SET_USER(state, payload) {
        state.user = payload 
        if(payload !== null){
            state.userName = `${payload.firstName} ${payload.lastName}`
            if (payload.firstName) {
                state.userFirstName = payload.firstName
            }
            if (payload.lastName) {
                state.userLastName = payload.lastName
            }
            if (payload.userRole) {
                state.userRole = payload.userRole
            }
        }  

    },
    SET_CLIENTS(state, clients) {
      state.allClients = clients
    },

    SET_NUMOFDUETASKS(state, tasks) {
        state.numOfDueTasks = tasks
    },
    SET_PROPERTYTYPES(state, payload) {
        state.propertyTypes = payload
    },

    SET_NOTIFICATIONVIEW(state, payload){
        state.isNotificationExpanded = payload
    },
    SET_NOTIFICATIONS(state, payload){
        if(payload === null)
            state.notifications = []
        else{
            //to stop adding duplicates
            if (!state.notifications.includes(payload)) {
                state.notifications.push(payload)
            }
        }
    },

    SET_NOTES(state, payload){
        state.notes = payload
    },
    SET_TASKS(state, payload){
        state.tasks = payload
    },
    SET_CHATVIEW(state, payload){
        state.isChatMessageOpen = payload
    },
    SET_RECENTSENTCHATLOG(state, payload){
        state.selectedChatLog = payload
    },

    storePromise(state, payload) {
        Vue.set(state.endpointPromise, payload.key, payload.data);
    },
  },
  actions: {
    async authenicate({ commit, dispatch }, payload) {

      await Axios.get(payload.url, { params: payload.form }).then(function (response) {
          commit('SET_USER', response.data)
          dispatch('getClients')
          dispatch('getNumofDueTasks')
          dispatch('getPropertyTypes')
          return null;
      });
    },
    async refreshToken({ state, commit }, key) {

        var promiseKey = "RefreshTokenUser" + key.refreshToken;

        //return existing promise, prevents multiple api calls
        if (state.endpointPromise[promiseKey] !== null && state.endpointPromise[promiseKey] !== undefined)
            return state.endpointPromise[promiseKey];

        //call endpoint
        let promise = Axios.post('Auth/refresh-token', key)
            .then(function (response) {
                commit('user', response.data); //set data
                commit('storePromise', { key: promiseKey, data: null }); //clear promise
                return response.data.accessToken;
            });

        commit('storePromise', { key: promiseKey, data: promise });
        return promise;
    },

    loadNotes({ state, commit }, payload) {
        var that = this
        Axios.get(`Notes/get-notes`, { params: payload }).then(function (response) {
            commit('SET_NOTES', response.data)
        }).catch(() => {
            commit('SET_NOTES', { results: [] })
        })
    },
    loadTasks({ state, commit }, payload) {
        var that = this
        Axios.get(`Task/get-Tasks`, { params: payload }).then(function (response) {
            commit('SET_TASKS', response.data)
        }).catch(() => {
            commit('SET_TASKS', { results: [] })
        })
    },

    getClients({ commit }) {
        Axios.get('/Client/get-clients-list')
            .then(response => {
                commit('SET_CLIENTS', response.data)
            });
    },
    getNumofDueTasks({ state, commit }) {
        Axios.get(`/Task/user/${state.user.id}/get-number-of-due-tasks-today`)
            .then(response => {
                commit('SET_NUMOFDUETASKS', response.data)
            });
    },
    getPropertyTypes({ commit }) {
        Axios.get('Generic/property-types').then((response) => {
            commit('SET_PROPERTYTYPES', response.data)
        })
    },
  },
  modules: {
  }
})
