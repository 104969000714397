import store from '../store'
import router from '../router'

export default {
    async refreshAccessToken() {
        var refreshTokenRequest = {}

        var user = store.state.user;

        var res;
        if (user && user.refreshToken) {
            refreshTokenRequest.refreshToken = user.refreshToken;

            var path = router.app.$route.path;
            res = await store.dispatch('refreshToken', refreshTokenRequest).then((res) => {
                return res
            }).catch(() => {
                store.commit('SET_NOTIFICATIONVIEW', false)
                store.commit('SET_USER', null)
                window.sessionStorage.clear()
                router.push({ name: 'LoginView' });
                return null;
            });

        } else {
            store.commit('SET_NOTIFICATIONVIEW', false)
            store.commit('SET_USER', null)
            window.sessionStorage.clear()
            router.push({ name: 'LoginView' });
            return null;
        }
    }
}