import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Login from '../views/LoginView.vue'

Vue.use(VueRouter)

const routes = [
  {
    meta: {
        title: 'Default'
    },
    path: '/',
    name: 'MainView',
    component: () => import(/* webpackChunkName: "main" */ '../views/MainView.vue'),
    children: [
        {
            meta: {
                title: 'Login',
                authType: ['AdminUser', 'StandardUser']
            },
            path: '/login',
            name: 'LoginView',
            component: Login
        },
        {
            meta: {
                title: 'Home',
                authType: ['AdminUser', 'StandardUser']
            },
            path: '/Home',
            name: 'Home',
            component: () => import(/* webpackChunkName: "profile" */ '../views/Home.vue'),
            props: true
        },
        {
            meta: {
                title: 'Clients',
                authType: ['AdminUser', 'StandardUser']
            },
            path: '/Clients',
            name: 'Clients',
            component: () => import(/* webpackChunkName: "profile" */ '../views/Clients.vue')
        },
        {
            meta: {
                title: 'View Client',
                authType: ['AdminUser', 'StandardUser']
            },
            path: '/ViewClient/:id/:clientName',
            name: 'ViewClient',
            props: true,
            component: () => import(/* webpackChunkName: "profile" */ '../views/Clients/ViewClient.vue'),
        
        },
        {
            meta: {
                title: 'Add/Edit Insurance',
                authType: ['AdminUser', 'StandardUser']
            },
            path: '/add-edit-insurance/client/:id',
            name: 'AddEditInsurance',
            props: true,
            component: () => import(/* webpackChunkName: "profile" */ '../views/Clients/AddEditInsurance.vue'),    
        },
        {
            meta: {
                title: 'Add/Edit Mortgage',
                authType: ['AdminUser', 'StandardUser']
            },
            path: 'client/:clientId/add-edit-mortgage/:mortgageId',
            name: 'AddEditMortgage',
            props: true,
            component: () => import(/* webpackChunkName: "profile" */ '../views/Clients/AddEditMortgage.vue'),    
        },
        {
            meta: {
                title: 'Mortgages Kanban',
                authType: ['AdminUser', 'StandardUser']
            },
            path: 'mortgages-kanban',
            name: 'Kanban',
            props: true,
            component: () => import(/* webpackChunkName: "profile" */ '../views/Kanban.vue'),    
        },

        {
            meta: {
                title: 'Tasks',
                authType: ['AdminUser', 'StandardUser']
            },
            path: '/Tasks',
            name: 'Tasks',
            component: () => import(/* webpackChunkName: "profile" */ '../views/Tasks.vue')
        },
        {
            meta: {
                title: 'Leads',
                authType: ['AdminUser', 'StandardUser']
            },
            path: '/Leads',
            name: 'Leads',
            component: () => import(/* webpackChunkName: "profile" */ '../views/Leads.vue')
        },
        {
            meta: {
                title: 'Introducers',
                authType: ['AdminUser', 'StandardUser']
            },
            path: '/Introducers',
            name: 'Introducers',
            component: () => import(/* webpackChunkName: "profile" */ '../views/Introducers.vue')
        },
        {
            meta: {
                title: 'View Introducer',
                authType: ['AdminUser', 'StandardUser']
            },
            path: '/Introducers/ViewIntroducer/:id',
            name: 'ViewIntroducer',
            props: true,
            component: () => import(/* webpackChunkName: "profile" */ '../views/Introducers/ViewIntroducer.vue'),
        },
        {
            meta: {
                title: 'Mortgage Report',
                authType: ['AdminUser', 'StandardUser']
            },
            path: '/mortgage-report',
            name: 'MortgageReport',
            props: true,
            component: () => import(/* webpackChunkName: "profile" */ '../views/MortgageReport.vue'),
        },
        /* User Management*/
        {
            meta: {
                title: 'View Users',
                authType: ['AdminUser', 'StandardUser']
            },
            path: '/ViewUsers',
            name: 'ViewUsers',
            props: true,
            component: () => import(/* webpackChunkName: "profile" */ '../views/UserManagement/ViewUsers.vue'),
        
        },
        {
            meta: {
                title: 'Edit or Create User',
                authType: ['AdminUser', 'StandardUser']
            },
            path: '/CreateUser/:id/',
            name: 'CreateUser',
            props: true,
            component: () => import(/* webpackChunkName: "profile" */ '../views/UserManagement/CreateUser.vue'),     
        },
        {
            meta: {
                title: 'Reset Password',
                authType: ['AdminUser', 'StandardUser']
            },
            path: '/reset-password/:id/',
            name: 'ResetPassword',
            props: true,
            component: () => import(/* webpackChunkName: "profile" */ '../views/UserManagement/ResetPassword.vue'),     
        },
        {
            meta: {
                title: 'File Manager',
                authType: ['AdminUser', 'StandardUser']
            },
            path: '/file-manager',
            name: 'FileManager',
            props: true,
            component: () => import(/* webpackChunkName: "profile" */ '../views/FileManager.vue'),     
        },
        {
            meta: {
                title: 'Billing',
                authType: ['Master', 'Admin']
            },
            path: '/Billing/',
            name: 'Billing',
            props: true,
            component: () => import(/* webpackChunkName: "profile" */ '../views/UserManagement/Billing.vue'),     
        },
    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
    const user = store.state.user;
    if (user === null && (to.name === 'MainView' || to.name === 'LoginView')) {
        if (to.name !== 'LoginView' && from.name !== 'LoginView') {
          // If not already on the LoginView or MainView, navigate to LoginView
          router.push({ name: 'LoginView' });
        } else {
          next();
        }
    } 
    else {
        next();
    }
  
})

export default router
