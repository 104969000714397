export default {
    methods: {
        isMaster() {
            if (this.$store.state.user !== null)
                return this.$store.state.user.userRole === 'Master'
        },
        isAdminOrManager() {
            if (this.$store.state.user !== null)
                return this.$store.state.user.userRole === 'Admin' || this.$store.state.user.userRole === 'Manager'
        },
        formatDate(value) {
            if (value !== '-' && value !== null && value !== undefined) {
                return this.$dayjs(value).format('DD MMM YYYY')
            }
        },
        formatDateCustom(value, f){
            if (value !== '-' && value !== null) {
                return this.$dayjs(value).format(f)
            }
        },
        formatDateTime(value) {
            if (value !== '-' && value !== null) {
                return this.$dayjs(value).format('DD MMM YYYY - HH:mm')
            }
        },
        formatDateSQL(value) {
            return this.$dayjs(value).format('YYYY-MM-DD HH:mm:ss')
        },
        formatOnlyDate(value) {
            return this.$dayjs(value).format('DD MMM YYYY')
        },
        formatOnlyTime(value) {
            return this.$dayjs(value).format('HH:mm:ss')
        },
        timeElapsed(inputDate) {
            return this.$dayjs(inputDate).fromNow()
        },
        trimLength(string, length) {
            return string.length > length ? string.substring(0, length - 3) + "..." : string.substring(0, length);
        },
        NoteTypes(type){
            switch(type){
                case "AllUserNotes":
                    return 1;
                case "ClientNotes":
                    return 2;
                default:
                    return 1;
            }
        },

        insuranceType(id){
            if(id === 1)
                return 'Protection'
            else if(id === 2)
                return 'General'
            else if(id === 3)
                return 'Medical'
        },
        hasValidationErrors(model,field){
            return model.some(obj => obj.hasOwnProperty(field));
        },
        getValidationErrors(model,field){
            return model.find(obj => obj.hasOwnProperty(field))[field];
        }
    },
    computed: {
        isLoggedIn(){
          return this.$store.state.user !== null
        },
        role() {
            return this.$store.state.user.userRole
        },
    }
}